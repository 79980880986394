import React from 'react';
import styled from 'styled-components';
import BookImage from '../atoms/BookImageOrder';
import Quote from '../atoms/Quote';

const StyledBookWithQuote = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  z-index: 1;
  grid-column: 1/3;
  max-width: 550px;

  @media ${({ theme: { media } }) => media.tablet} {
    position: absolute;
    top: -30%;
    background-color: transparent;
    justify-self: end;
    grid-column: 1/2;
  }

  @media ${({ theme: { media } }) => media.laptop} {
    top: -52%;
  }
`;

const StyledBookImage = styled(BookImage)`
  position: relative;
  top: -120px;
  margin-bottom: -150px;
  width: 120%;
  z-index: 1;

  @media ${({ theme: { media } }) => media.tablet} {
    max-width: 600px;
    margin-bottom: -50px;
    top: 0%;
  }

  @media ${({ theme: { media } }) => media.laptop} {
    grid-column: 1/2;
    max-width: 640px;
    height: 740px;
  }
`;

const BookWithQuote = () => (
  <StyledBookWithQuote>
    <StyledBookImage />
    <Quote />
  </StyledBookWithQuote>
);

export default BookWithQuote;
