import React from 'react';
import styled from 'styled-components';
import ChaptersList from '../atoms/ChaptersLists';
import ChaptersHeader from '../atoms/ContentsHeader';

const StyledTableOfContents = styled.div`
  max-width: 1300px;
  justify-self: center;
  width: 100%;
  padding: 40px;
  padding-bottom: 0px;
`;

const TableOfContents = () => (
  <StyledTableOfContents>
    <ChaptersHeader />
    <ChaptersList />
  </StyledTableOfContents>
);

export default TableOfContents;
