import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledBookPreviewDecorator = styled.div`
  width: 100%;
  position: absolute;
  bottom: calc(50% - 1px);
  display: flex;
  align-items: flex-end;
`;

const BookPreviewDecorator = () => {
  const { data } = useStaticQuery(graphql`
    {
      data: file(relativePath: { eq: "separator-sekcji.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: {
              background: "rgba(0, 0, 0, 0)"
              color: "#b7926c"
            }
            quality: 90
          )
        }
      }
    }
  `);

  return (
    <StyledBookPreviewDecorator>
      <GatsbyImage
        alt="separator-sekcji"
        image={getImage(data.childImageSharp)}
      />
    </StyledBookPreviewDecorator>
  );
};

export default BookPreviewDecorator;
