import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import styled from 'styled-components';
import BookImage from '../atoms/BookImageAbout';
import AboutContent from '../molecules/AboutContent';

const StyledBookImage = styled(BookImage)`
  top: -15%;
  width: 130%;
  margin-bottom: -120px;
  grid-column: 1/3;

  @media ${({ theme: { media } }) => media.tablet} {
    max-width: 600px;
  }

  @media ${({ theme: { media } }) => media.laptop} {
    top: -16%;
    margin-bottom: 0px;
    grid-column: 1/2;
    max-width: 665px;
    height: 760px;
  }
`;

const StyledAboutSection = styled(BgImage)`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.sandyLight};
  background-size: auto 80%;
  background-position: 50% 100%;

  @media (max-width: 1023px) {
    &::after,
    &::before {
      opacity: 0 !important;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-content: center;
`;

const About = () => {
  const { backgroundImage } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(relativePath: { eq: "mapa.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              quality: 90
            )
          }
        }
      }
    `
  );
  return (
    <StyledAboutSection
      Tag="section"
      image={getImage(backgroundImage)}
    >
      <ContentWrapper>
        <StyledBookImage />
        <AboutContent />
      </ContentWrapper>
    </StyledAboutSection>
  );
};

export default About;
