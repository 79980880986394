import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import propTypes from 'prop-types';

const StyledGatsbyImage = styled(GatsbyImage)`
  max-width: 500px;
  position: relative;
  left: 50%;
  transform: translateX(-67%);
`;

const BookImage = ({ className }) => {
  const { data } = useStaticQuery(graphql`
    {
      data: file(relativePath: { eq: "ksiazka-szum-stepu-i-pustyni.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: { background: "rgba(0, 0, 0, 0)" }
            quality: 90
          )
        }
      }
    }
  `);

  return (
    <StyledGatsbyImage
      className={className}
      alt="ksiazka-szum-stepu-i-pustyni"
      image={getImage(data.childImageSharp)}
    />
  );
};

BookImage.propTypes = {
  className: propTypes.string,
};

BookImage.defaultProps = {
  className: '',
};

export default BookImage;
