import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledAboutImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145%;
  transform: translateX(-17%);
  margin-bottom: -20px;
  position: relative;
  z-index: 1;
  @media ${({ theme: { media } }) => media.tablet} {
    margin-bottom: -40px;
  }
  @media ${({ theme: { media } }) => media.laptop} {
    transform: translateX(-25%);
    margin-bottom: -60px;
  }
`;

const AboutImages = () => {
  const { data } = useStaticQuery(graphql`
    {
      data: file(relativePath: { eq: "zdjecia.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: { background: "rgba(0, 0, 0, 0)", color: "#fff" }
            quality: 90
          )
        }
      }
    }
  `);

  return (
    <StyledAboutImage>
      <GatsbyImage alt="zdjecia" image={getImage(data.childImageSharp)} />
    </StyledAboutImage>
  );
};

export default AboutImages;
