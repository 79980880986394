import React from 'react';
import styled from 'styled-components';
import { AiOutlineCopy } from 'react-icons/ai';
import { FiBook } from 'react-icons/fi';
import {RiArchiveFill, RiBarcodeFill} from 'react-icons/ri';
import { GoTag } from 'react-icons/go';
import typo from '../../styles/typography';

const StyledBookDetails = styled.div`
  justify-self: center;
  margin-top: 30px;
  padding-bottom: 120px;
  grid-column: 1/3;

  h1 {
    font-size: 1.75em;
  }

  & > div {
    padding: 30px;
    padding-right: 0;

    div {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.5em;
  }

  .bold-text {
    margin-left: 20px;
    margin-right: 5px;
    font-weight: bold;
  }

  .price-tag {
    margin-top: 30px;
    margin-bottom: 0px;
    svg {
      transform: scale(-1, 1);
      font-size: 1.5em;
    }
  }

  .price {
    display: flex;
    align-items: flex-end;

    p {
      margin-bottom: 5px;
      margin-left: 10px;
    }
    h2 {
      font-family: ${typo.lato};
      font-size: 3em;
      margin-left: 40px;
    }
  }

  .line-break {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }

  @media ${({ theme: { media } }) => media.tablet} {
    justify-self: start;
    grid-column: 2/3;
    padding-bottom: 0px;

    .bold-text {
      margin-left: 8px;
    }

    & > div {
      padding-left: 0px;
    }

    svg {
      font-size: 1.8em;
    }
  }

  @media ${({ theme: { media } }) => media.laptop} {
    width: 100%;
    max-width: 420px;
    .line-break {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

const BookDetails = () => (
  <StyledBookDetails>
    <p>Seweryn Błasiak</p>
    <h2>“Szum stepu i pustyni”</h2>
    <div>
      <div className="line-break">
        <div className="first-child">
          <AiOutlineCopy />
          <p className="bold-text">Liczba stron:</p>
          <p>200</p>
        </div>
        <div>
          <FiBook />
          <p className="bold-text">Oprawa:</p>
          <p>twarda</p>
        </div>
      </div>

      <div>
        <div  className="first-child">
          <RiBarcodeFill />
          <p className="bold-text">ISBN:</p>
          <p>978-83-940492-0-1</p>
        </div>
      </div>
      <div className="price-tag">
        <GoTag />
        <p className="bold-text">Cena:</p>
      </div>
      <div className="price">
        <h2>59 zł</h2> <p> wysyłka gratis!</p>
      </div>
      <div>
        <RiArchiveFill />
        <p className="bold-text">Czas wysyłki:</p>
        <p>3 dni robocze</p>
      </div>
    </div>
  </StyledBookDetails>
);

export default BookDetails;
