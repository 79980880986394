import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledBookInsideImage = styled.div`
  width: 170%;
  position: relative;
  max-width: 600px;
  left: 40px;
  top: 40px;
  z-index: 1;

  @media ${({ theme: { media } }) => media.smallTablet} {
    max-width: 1250px;
    width: 80%;
    left: 0px;
    top: 20px;
    margin-bottom: -20px;
  }

  @media ${({ theme: { media } }) => media.tablet} {
    margin-bottom: -50px;
  }

  @media ${({ theme: { media } }) => media.desktop} {
    margin-bottom: -120px;
  }
`;

const BookInsideImage = () => {
  const { data } = useStaticQuery(graphql`
    {
      data: file(relativePath: { eq: "preview1.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: { background: "rgba(0, 0, 0, 0)", color: "#fff" }
            quality: 90
          )
        }
      }
    }
  `);

  return (
    <StyledBookInsideImage>
      <GatsbyImage
        alt="podgląd-książki"
        image={getImage(data.childImageSharp)}
      />
    </StyledBookInsideImage>
  );
};

export default BookInsideImage;
