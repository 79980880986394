import React from 'react';
import styled from 'styled-components';
import AboutAuthor from '../atoms/AboutAuthor';
import AuthorImage from '../atoms/AuthorImage';
import AuthorImageDescription from '../molecules/AuthorImageDescription';

const StyledAuthor = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  background-color: ${({ theme }) => theme.colors.sandyDark};
`;

const Author = () => (
  <StyledAuthor id="Autor">
    <AuthorImage />
    <AboutAuthor />
    <AuthorImageDescription />
  </StyledAuthor>
);

export default Author;
