import React from 'react';
import styled from 'styled-components';

const StyledAboutAuthor = styled.div`
  position: relative;
  z-index: 50;
  max-width: 520px;
  grid-column: 1/3;

  padding: 35px;

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: 1.125em;
    line-height: 1.4em;
    letter-spacing: 0.24px;
  }

  @media ${({ theme: { media } }) => media.smallTablet} {
    p {
      font-size: 18px;
    }
    grid-column: 1/2;
    grid-row: 1;
  }

  @media ${({ theme: { media } }) => media.laptop} {
    box-shadow: -3.63px 19px 90px #0000004a;
    background-color: ${({ theme }) => theme.colors.sandyDark};
    justify-self: end;
    align-self: end;
    top: 60px;
    left: 60px;
    max-width: 620px;
    h2 {
      margin-bottom: 35px;
      max-width: calc(100% - 30px);
    }
    padding: 80px 50px;
  }

  @media ${({ theme: { media } }) => media.desktop} {
    max-width: 720px;
    h2 {
      margin-bottom: 35px;
      max-width: calc(100% - 100px);
    }
    p {
      max-width: calc(100% - 40px);
    }
    padding: 100px 50px;
    h2 {
      font-size: 32px;
    }
  }
`;

const AboutAuthor = () => (
  <StyledAboutAuthor>
    <h2>Seweryn Błasiak</h2>
    <h2>
      - nieprzeciętny w swoim zamiłowaniu do tego rodzaju fotografii, z
      nieszablonowymi pomysłami na kolejne kolejowe kadry, cierpliwy i
      konsekwentny w ich zdobywaniu.
    </h2>
    <p>
      Gotów poświęcić wiele dla radości duszy, oka, dla tych niezwykłych chwil,
      w których przestrzeń przecina dźwięk jadącego składu.
    </p>
  </StyledAboutAuthor>
);

export default AboutAuthor;
