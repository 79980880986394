import React from 'react';
import styled from 'styled-components';
import BookInsideImage from '../atoms/BookInsideImage';
import BookPreviewDecorator from '../atoms/BookPreviewDecorator';

const StyledBookPreview = styled.div`
  position: relative;

  @media ${({ theme: { media } }) => media.smallTablet} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Filler = styled.div`
  height: 50%;
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.sandyDark};
  top: 50%;
  left: 0;
`;

const BookPreview = () => (
  <StyledBookPreview>
    <BookInsideImage />
    <BookPreviewDecorator />
    <Filler />
  </StyledBookPreview>
);

export default BookPreview;
