import React from 'react';
import Layout from '../components/templates/Layout';
import Navbar from '../components/molecules/Navbar';
import Header from '../components/organisms/Header';
import About from '../components/organisms/About';
import BookContents from '../components/organisms/BookContents';
import Author from '../components/organisms/Author';
import BookDescription from '../components/organisms/BookDescription';
import Order from '../components/organisms/Order';
import Footer from '../components/organisms/FooterIndex';
import SEO from '../components/SEO';

const IndexPage = () => (
  <>
    <SEO 
    title="Strona główna książki Szum stepu i pustyni Seweryna Błasiaka"
    keywords={[`książka`, `szum`, `stepu`, `pustyni`, `podróże`]} />
    <Layout>
      <Navbar />
      <Header />
      <About />
      <BookContents />
      <Author />
      <BookDescription />
      <Order />
      <Footer />
    </Layout>
  </>
);

export default IndexPage;
