import React from 'react';
import styled from 'styled-components';
import AboutDecorator from '../atoms/AboutDecorator';
import AboutImage from '../atoms/AboutImage';

const StyledAboutContent = styled.div`
  justify-self: center;
  padding: 25px;
  width: 100%;
  max-width: 600px;
  position: relative;
  grid-column: 1/3;

  p {
    font-size: 1.125em;
    letter-spacing: 0.24px;
    line-height: 1.6em;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 1.5em;
    letter-spacing: 0.24px;
    margin-bottom: 30px;
  }

  .last-child {
    margin: 0;
  }

  @media ${({ theme: { media } }) => media.mobile} {
    padding: 30px;
  }

  @media ${({ theme: { media } }) => media.tablet} {
    p {
      font-size: 18px;
    }
    h3 {
      font-size: 28px;
      max-width: calc(100% - 50px);
    }
  }

  @media ${({ theme: { media } }) => media.laptop} {
    justify-self: flex-start;
    padding-left: 0;
    grid-column: 2/3;
  }

  @media ${({ theme: { media } }) => media.desktop} {
    h3 {
      font-size: 32px;
    }
  }
`;

const AboutContent = () => (
  <StyledAboutContent id="About">
    <p>Seweryn dokonał rzeczy wyjątkowej.</p>
    <h3>
      Zainspirowany fotografią mongolskiej linii kolejowej, nie mając żadnego
      doświadczenia w obcowaniu z pozaeuropejską przestrzenią, wyrusza w podróż,
      by poczuć smak spełnionego marzenia.
    </h3>
    <p>
      Autor zaprasza nas w niesamowitą podróż, w trakcie której odwiedził
      odległe chińskie zakątki linii Jitong i Jingtong oraz niemal całą kolejową
      Mongolię. &quot;Szum stepu i pustyni&quot;to bogato ilustrowana,
      zachwycająca, dojrzała i szczera opowieść o drodze, o walce z własnymi
      lękami i różnymi przeciwnościami losu, o poznawaniu siebie.
    </p>
    <AboutImage />
    <p className="last-child">
      Ta bardzo osobista relacja Autora sprawia, że i my możemy
      <b>
        {' '}
        odbyć spacer ulicami chińskiej stolicy, zajrzeć na jej przedmieścia i
        kolejową prowincję, znaleźć się w przygranicznym, robotniczym barze i
        wrzuconej w pustynię lokomotywowni, przemierzyć dziką Gobi i bezkresny
        mongolski step,{' '}
      </b>
      poznając jego mieszkańców, doświadczając ich życzliwości, poczuć ogromne
      zmęczenie, ale przede wszystkim niesamowitą satysfakcję wynikającą z faktu
      obcowania z pięknem natury.
    </p>
    <AboutDecorator />
  </StyledAboutContent>
);

export default AboutContent;
