import React from 'react';
import styled from 'styled-components';
import BookDetails from '../molecules/BookDetails';

const StyledBookDescription = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.sandyLight};
`;

const DetailsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-content: center;
  @media ${({ theme: { media } }) => media.laptop} {
    grid-column-gap: 30px;
  }
`;

const BookDescription = () => (
  <StyledBookDescription>
    <DetailsWrapper>
      <BookDetails />
    </DetailsWrapper>
  </StyledBookDescription>
);

export default BookDescription;
