import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 130%;
  max-height: 420px;
  max-width: 700px;
  opacity: 1;
  grid-column: 1/3;

  @media ${({ theme: { media } }) => media.smallTablet} {
    grid-column: 2/3;
    grid-row: 1;
    width: 100%;
    align-self: start;
    min-height: 600px;
    max-height: 860px;
    max-width: 960px;
  }
`;

const AuthorImage = () => {
  const { data } = useStaticQuery(graphql`
    {
      data: file(relativePath: { eq: "autor-seweryn-blasiak.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: { background: "rgba(0, 0, 0, 0)", color: "#fff" }
            quality: 90
          )
        }
      }
    }
  `);

  return (
    <StyledGatsbyImage
      alt="autor-seweryn-blasiak"
      image={getImage(data.childImageSharp)}
    />
  );
};

export default AuthorImage;
