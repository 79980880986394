import React from 'react';
import styled from 'styled-components';
import CompanyInfo from '../atoms/CompanyInfo';
import Socials from '../atoms/Socials';
import UseTerms from '../atoms/UseTerms';
import UseStatute from '../atoms/Statute';
import UseIndex from '../atoms/IndexFooter';

const StyledFooterContent = styled.div`
  display: grid;
  width: 100%;
  max-width: 1200px;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 50px;
  padding: 40px 30px;
  font-size: 14px;

  @media ${({ theme: { media } }) => media.tablet} {
    grid-column-gap: 120px;
  }

  @media ${({ theme: { media } }) => media.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledCompanyInfo = styled(CompanyInfo)`
  grid-column: 1/5;
  @media ${({ theme: { media } }) => media.tablet} {
    grid-column: 1/3;
    justify-self: start;
  }
  @media ${({ theme: { media } }) => media.laptop} {
    grid-column: 1/2;
  }
`;

const StyledSocials = styled(Socials)`
  grid-column: 1/5;
  @media ${({ theme: { media } }) => media.tablet} {
    grid-column: 3/5;
    justify-self: start;
  }
  @media ${({ theme: { media } }) => media.laptop} {
    grid-column: 2/3;
    justify-self: start;
  }
`;

const StyledUseTerms = styled(UseTerms)`
  grid-column: 1/5;
  @media ${({ theme: { media } }) => media.tablet} {
    grid-column: 1/3;
    justify-self: start;
  }
  @media ${({ theme: { media } }) => media.laptop} {
    grid-column: 4/4;
  }
`;

const StyledUseStatueLink = styled(UseStatute)`
  grid-column: 1/5;
  @media ${({ theme: { media } }) => media.tablet} {
    grid-column: 4/5;
    justify-self: start;
  }
  @media ${({ theme: { media } }) => media.laptop} {
    grid-column: 3/4;
  }
`;

const StyledUseIndexLink = styled(UseIndex)`
  grid-column: 1/5;
  @media ${({ theme: { media } }) => media.tablet} {
    grid-column: 4/5;
    justify-self: start;
  }
  @media ${({ theme: { media } }) => media.laptop} {
    grid-column: 3/4;
  }
`;
const FooterContent = () => (
  <StyledFooterContent>
    <StyledCompanyInfo />
    {/* <StyledSocials /> */}
    <StyledUseStatueLink />
    <StyledUseTerms />
  </StyledFooterContent>
);

export default FooterContent;
