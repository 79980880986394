import React from 'react';
import styled from 'styled-components';
import BookPreview from '../molecules/BookPreview';
import TableOfContents from '../molecules/TableOfContents';

const StyledBookContents = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.sandyLight};
`;

const BookContents = () => (
  <StyledBookContents id="Ksiazka">
    <TableOfContents />
    <BookPreview />
  </StyledBookContents>
);

export default BookContents;
