import React, { useState, useEffect, componentDidMount } from 'react';

import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Button from '../atoms/Button';
import { BiCheckbox } from 'react-icons/all';
import { Link } from '../../../.cache/gatsby-browser-entry';

const StyledForm = styled.form`
  justify-self: center;
  max-width: 550px;
  grid-column: 1/3;
  padding: 30px 15px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  place-items: center;
  row-gap: 12px;
  position: relative;

  h2 {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 1.75em;
    grid-column: 1/13;
    margin-bottom: 15px;
  }

  label {
    position: relative;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.dark};
    grid-column: 1/13;
    width: 100%;
  }

  input {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grey};
    outline: none;
    border: none;
    color: ${({ theme }) => theme.colors.dark};
    border-radius: 20px;
    padding: 18px;
    ::placeholder {
      color: ${({ theme }) => theme.colors.dark};
    }
  }

  select {
    margin-top: 3px;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 15px 15px;
    outline: 0;
    border: 0px solid #000000;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.grey};
    color: #000000;
    /* appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; */
  }
  select::-ms-expand {
    display: none;
  }
  select:hover,
  select:focus {
    color: #000000;
    background: ${({ theme }) => theme.colors.grey};
  }
  select:disabled {
    opacity: 0;
    pointer-events: none;
  }

  span {
    position: absolute;
    top: -15px;
    /* left: 14px; */
    color: rgb(255, 0, 0);
  }

  .count,
  .shipping {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .count {
    grid-column: 1/6;
    select {
      max-width: 60%;
    }
  }

  .shipping {
    grid-column: 6/13;
    span {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .street {
    grid-column: 1/13;
  }

  .streetnumber {
    grid-column: 1/7;
    padding-right: 5px;
  }

  .postcode {
    grid-column: 7/13;
    padding-left: 5px;
  }

  .city {
    grid-column: 1/13;
  }

  .control {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    margin-top: 10px;
  }

  .control span {
    top: -18px;
    left: 5px;
  }

  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .control_indicator {
    position: absolute;
    top: 0px;
    left: 5px;
    height: 20px;
    width: 20px;
    background: ${({ theme }) => theme.colors.grey};
    border: 0px solid #000000;
    border-radius: 0px;
  }

  .control:hover input ~ .control_indicator,
  .control input:focus ~ .control_indicator {
    background: #cccccc;
  }

  .control input:checked ~ .control_indicator {
    background: ${({ theme }) => theme.colors.touch};
  }

  .control:hover input:not([disabled]):checked ~ .control_indicator,
  .control input:checked:focus ~ .control_indicator {
    background: ${({ theme }) => theme.colors.touch};
  }

  .control input:disabled ~ .control_indicator {
    background: #eeecec;
    opacity: 1;
    pointer-events: none;
  }

  .control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
  }

  .control input:checked ~ .control_indicator:after {
    display: block;
  }

  .control-checkbox .control_indicator:after {
    left: 8px;
    top: 2px;
    width: 4px;
    height: 10px;
    border: solid ${({ theme }) => theme.colors.primary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #cac8c8;
  }

  .message {
    width: 100%;
    position: absolute;
    left: 15px;
    bottom: -5px;
    color: #000;
    font-size: 18px;
    color: #ff0000;
  }

  @media ${({ theme: { media } }) => media.mobile} {
    span {
      left: 14px;
    }
  }

  @media ${({ theme: { media } }) => media.tablet} {
    justify-self: start;
    grid-column: 2/3;
    h2 {
      justify-self: start;
    }

    button {
      justify-self: start;
    }
  }

  @media ${({ theme: { media } }) => media.laptop} {
    select {
      margin-top: 0px;
    }

    .street {
      grid-column: 1/8;
      padding-right: 5px;
    }

    .streetnumber {
      grid-column: 8/13;
      padding-right: 0px;
      padding-left: 5px;
    }

    .postcode {
      grid-column: 1/5;
      padding-left: 0px;
      padding-right: 5px;
    }

    .city {
      grid-column: 5/13;
      padding-left: 5px;
    }

    .count,
    .shipping {
      flex-direction: row;
      justify-content: flex-end;
      select {
        margin-left: 10px;
      }
    }

    .count {
      select {
        max-width: 30%;
      }
    }

    .shipping {
      select {
        max-width: 60%;
      }
      span {
        left: 60%;
        transform: translateX(-50%);
      }
    }
  }
`;

const StyledButton = styled(Button)`
  grid-column: 1/13;
  padding: 13px 30px;
  font-size: 13px;
  margin-top: 20px;
  row-gap: 12px;
  width: 170px;

  @media ${({ theme: { media } }) => media.laptop} {
    padding: 16px 50px;
    width: 210px;
  }
`;

const rgxPatterns = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  fullName: /^([\w]{2,})+\s+([\w\s]{2,})+$/i,
  postcode: /^[0-9]{2}-[0-9]{3}$/,
};

const Form = () => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    watch,
    setValue
  } = useForm({ mode: 'onBlur' });
  const [responseError, setResponseError] = useState('');
  const [initialSubmitDisable, setInitialSubmitDisable] = useState(true);
  const watchShipping = watch('shipping'); // you can supply default value as second argument

  useEffect(() => {
    setInitialSubmitDisable(false);
  });

    useEffect(() => {
        easyPack.init({
            defaultLocale: 'pl',
            mapType: 'osm',
            searchType: 'osm',
            points: {
                types: ['parcel_locker']
            },
            map: {
                initialTypes: ['parcel_locker']
            }
        });
    }, []);

    const openInpostModal = () => {
        easyPack.modalMap(function(point, modal) {
            modal.closeModal();
            setValue('inpost_box', point.name);
            console.log(point.name);
        }, {width: 500, height: 600});
    }


  const onSubmit = async (data) => {
    console.log(data);
    const settings = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'origin-list',
      },
      body: JSON.stringify(data),
    };
    try {
      const fetchResponse = await fetch(
        'https://system.szumstepuipustyni.pl/system/zamow',
        settings
      );
      const { url } = await fetchResponse.json();
      window.location.href = url;
    } catch (e) {
      setResponseError(e.message);
      // reset()
    }
  };

  return (
    <StyledForm id="Zamowienie" onSubmit={handleSubmit(onSubmit)}>
      <h2>Zamów książkę:</h2>
        {/*<div style={{"min-height": "400px"}}>*/}
        {/*    <h3 style={{"color":"red"}}>Zamówienia przez formularz wstrzymane.</h3>*/}
        {/*</div>*/}
      <label htmlFor="fullName">
        {errors.fullName && <span>Przedstaw się poprawnie!</span>}
        <input
          {...register('fullName', {
            required: true,
            pattern: rgxPatterns.fullName,
          })}
          id="fullName"
          type="text"
          placeholder="Imię i nazwisko"
        />
      </label>
      <label htmlFor="mail">
        {errors.mail && <span>Podaj prawidłowy e-mail!</span>}
        <input
          {...register('mail', { required: true, pattern: rgxPatterns.email })}
          id="mail"
          type="mail"
          placeholder="Adres e-mail"
        />
      </label>
      <label htmlFor="count" className="count">
        Liczba egzemplarzy:
        <select {...register('count')} id="count">
          <option defaultValue value="1">
            1
          </option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </label>
      <label htmlFor="shipping" className="shipping">
        Forma dostawy:
        {errors.shipping && <span>Wybierz dostawę!</span>}
        <select
          {...register('shipping', { required: true, minLength: 1 })}
          id="shipping"
        >
          <option value="" disabled selected hidden>
            Wybierz dostawę
          </option>
          <option value="1">Kurier</option>
          <option value="3">Paczkomat InPost</option>
        </select>
      </label>
        {watchShipping === '3' && (
        <label htmlFor="phone" className="phone">
            {errors.phone && <span>Podaj prawidłowy numer telefonu!</span>}
            <input
                {...register('phone', {
                    required: true,
                    minLength: 2,
                    maxLength: 12,
                })}
                id="phone"
                type="text"
                placeholder="Numer telefonu"
            />
        </label>
        )}
        {watchShipping === '3' && (
        <label htmlFor="inpost_box" className="inpost_box">
            {errors.inpost_box && <span>Podaj prawidłową nazwę paczkomatu!</span>}
            <input
                {...register('inpost_box', {
                    required: true,
                    minLength: 3,
                    maxLength: 12,
                })}
                id="inpost_box"
                type="text"
                placeholder="Paczkomat"
            />
        </label>
        )}
        {watchShipping === '3' && <StyledButton onClick={openInpostModal} text={'Wybierz paczkomat na mapie'}/>}
      <label htmlFor="street" className="street">
        {errors.street && <span>Podaj prawidłowy adres!</span>}
        <input
          {...register('street', {
            required: true,
            minLength: 2,
            maxLength: 50,
          })}
          id="street"
          type="text"
          placeholder="Ulica"
        />
      </label>
      <label htmlFor="streetnumber" className="streetnumber">
        {errors.streetnumber && <span>Podaj prawidłowy numer!</span>}
        <input
          {...register('streetnumber', {
            required: true,
            min: 0,
            max: 999,
          })}
          id="streetnumber"
          type="text"
          placeholder="Nr domu/lokalu"
        />
      </label>
      <label htmlFor="postcode" className="postcode">
        {errors.postcode && <span>Podaj prawidłowy kod!</span>}
        <input
          {...register('postcode', {
            required: true,
            minLength: 2,
            maxLength: 10,
            pattern: rgxPatterns.postcode,
          })}
          id="postcode"
          type="text"
          placeholder="Kod pocztowy"
        />
      </label>
      <label htmlFor="city" className="city">
        {errors.city && <span>Podaj prawidłowe miasto!</span>}
        <input
          {...register('city', { required: true, minLength: 2, maxLength: 50 })}
          id="city"
          type="text"
          placeholder="Miejscowość"
        />
      </label>
      <label htmlFor="terms" className="control control-checkbox">
        Zapoznałem się i akceptuję <Link to = '/statute'>regulamin i politykę prywatnosci</Link>
        <input
          {...register('terms', { required: true })}
          id="terms"
          type="checkbox"
          />
        {errors.terms && <span>Zaakceptuj warunki!</span>}
        <div className="control_indicator" />
      </label>

      <StyledButton
        text="Zamów książkę"
        type="submit"
        disabled={initialSubmitDisable}
        isLoading={isSubmitting}
      />

      {responseError && (
        <p className="message">Coś poszło nie tak: {responseError}</p>
      )}
    </StyledForm>
  );
};

export default Form;
