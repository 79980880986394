import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import styled from 'styled-components';
import SectionSeparator from '../atoms/SectionSeparator';

const StyledBgImage = styled(BgImage)`
  position: relative;
  width: 100%;
  height: 450px;
  background-position: 25%;
  background-color: #3d2916;
  grid-column: 1/3;

  @media ${({ theme: { media } }) => media.tablet} {
    height: 600px;
  }

  @media ${({ theme: { media } }) => media.laptop} {
    height: 800px;
  }

  @media ${({ theme: { media } }) => media.desktop} {
    height: 1000px;
  }
`;

const StyledImageText = styled.div`
  max-width: 400px;
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media ${({ theme: { media } }) => media.tablet} {
    height: auto;
    position: absolute;
    top: 48%;
    left: 35%;
    transform: translate(-50%, -50%);
    max-width: 500px;
  }

  @media ${({ theme: { media } }) => media.laptop} {
    margin-left: 0px;
    max-width: 680px;
    h2 {
      font-size: 28px;
    }
  }

  @media ${({ theme: { media } }) => media.laptop} {
    h2 {
      font-size: 32px;
    }
  }
`;

const AuthorImageDescription = () => {
  const { backgroundImage } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(
          relativePath: {
            eq: "szum-stepu-i-pustyni-seweryn-blasiak-slajder2-slajd1.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              quality: 90
            )
          }
        }
      }
    `
  );
  return (
    <StyledBgImage image={getImage(backgroundImage)}>
      <StyledImageText>
        <h2>
          Lekkie pióro i wrażliwe oko Autora przenoszą nas w jego wewnętrzny
          świat, w magiczny wymiar, w którym życie nieoczekiwanie nabiera
          prawdziwej głębi.
        </h2>
      </StyledImageText>
      <SectionSeparator />
    </StyledBgImage>
  );
};

export default AuthorImageDescription;
