import React from 'react';
import styled from 'styled-components';

const StyledQuote = styled.div`
  padding: 35px;
  padding-top: 0;

  h3,
  p {
    text-align: center;
    color: #000;
  }

  h3 {
    font-size: 1.3em;
    font-style: italic;
    margin-bottom: 30px;
    position: relative;

    ::after {
      position: absolute;
      content: '';
      width: 35px;
      height: 1px;
      background-color: #000;
      top: calc(100% + 18px);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media ${({ theme: { media } }) => media.laptop} {
    padding: 10px;
    padding-top: 0;
    h3 {
      font-size: 26px;
    }
  }
`;

const Quote = () => (
  <StyledQuote>
    <h3>
      “Niesamowita podróż w egzotyczne dla europejczyka zakątki świata, pełna
      przygód i namacalnej pasji do kolei.”
    </h3>

    <p>Anna Kowalska, Bookworm.pl</p>
  </StyledQuote>
);

export default Quote;
