import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.div`
  text-align: center;
  padding: 15px 30px 30px;
  max-width: 800px;

  & > p {
    margin-bottom: 20px;
    font-size: 14px;
  }

  @media ${({ theme: { media } }) => media.mobile} {
    padding: 15px 40px 30px;
    & > p {
      font-size: 16px;
    }
  }

  @media ${({ theme: { media } }) => media.tablet} {
    & > p {
      margin-bottom: 40px;
      font-size: 18px;
    }
  }

  @media ${({ theme: { media } }) => media.desktop} {
    padding: 40px 0px;
    h1 {
      font-size: 44px;
    }
  }
`;

const Title = () => (
  <StyledTitle>
    <p>
      Książka podróżnicza Seweryna Błasiaka <br /> “Szum stepu i pustyni” - już
      dostępna!
    </p>
    <h1>
      Ruszaj w bogato ilustrowaną podróż po Chinach i Mongolii śladami kolei.
    </h1>
  </StyledTitle>
);

export default Title;
