import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledAboutImage = styled.div`
  position: absolute;
  z-index: 100;
  top: 15%;
  right: -8%;
  transform: rotate(1deg);

  @media ${({ theme: { media } }) => media.tablet} {
    top: 18%;
  }
`;

const AboutDecorator = () => {
  const { data } = useStaticQuery(graphql`
    {
      data: file(relativePath: { eq: "ozdobnik-margines.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            tracedSVGOptions: { background: "rgba(0, 0, 0, 0)", color: "#fff" }
          )
        }
      }
    }
  `);

  return (
    <StyledAboutImage>
      <GatsbyImage
        alt="ozdobnik-margines"
        image={getImage(data.childImageSharp)}
      />
    </StyledAboutImage>
  );
};

export default AboutDecorator;
