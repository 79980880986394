import React from 'react';
import styled from 'styled-components';

const StyledChaptersList = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ul {
    width: 100%;
    margin-bottom: 20px;
    grid-column: 1/13;
  }

  .third-child {
    margin-bottom: 0px;
  }

  li {
    list-style: none;
    font-size: 1.125em;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  div {
    width: 35px;
    margin-left: 3px;
    display: flex;
    align-items: flex-end;
  }

  p {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  p::after {
    content: '';
    transform: translateX(5px);
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    height: 1px;
    width: 100%;
    bottom: 4px;
    position: absolute;
    opacity: 0.4;
  }

  @media ${({ theme: { media } }) => media.smallTablet} {
    ul {
      max-width: 310px;
      justify-self: center;
    }
    li {
      list-style: none;
      font-size: 18px;
    }
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    .first-child {
      grid-column: 1/7;
    }

    .second-child {
      grid-column: 7/13;
    }

    .third-child {
      grid-column: 4/10;
    }
  }

  @media ${({ theme: { media } }) => media.laptop} {
    display: flex;
    justify-content: space-between;
  }
`;

const ChaptersList = () => (
  <StyledChaptersList>
    {/* FIRST COLUMN */}
    <ul className="first-child">
      <li>
        <p>Beijing</p> <div>10</div>
      </li>
      <li>
        <p>Inny wymiar </p> <div>25</div>
      </li>
      <li>
        <p>Mongolia Wewnętrzna </p> <div>33</div>
      </li>
      <li>
        <p>Trudny czas </p> <div>56</div>
      </li>
      <li>
        <p>Kierunek Mongolia </p> <div>57</div>
      </li>
      <li>
        <p>Bez cenzury </p> <div>59</div>
      </li>
      <li>
        <p>Klątwa </p> <div>73</div>
      </li>
      <li>
        <p>Sajnbajnu </p>
        <div>74</div>
      </li>
      <li>
        <p>Dzień wyjazdu </p> <div>78</div>
      </li>
      <li>
        <p>Gobi</p> <div>83</div>
      </li>
      <li>
        <p>Nie bój się i idź</p> <div>84</div>
      </li>
      <li>
        <p>Żyjemy! </p> <div>86</div>
      </li>
      <li>
        <p>Gobi c.d. </p> <div>92</div>
      </li>
    </ul>

    {/* SECOND COLUMN */}
    <ul className="second-child">
      <li>
        <p>Gobijczycy </p>
        <div>94</div>
      </li>
      <li>
        <p>Zabierzcie mnie stąd! </p> <div>98</div>
      </li>
      <li>
        <p>Tsagaankhad </p> <div>102</div>
      </li>
      <li>
        <p>Ciąg dalszy następuje </p> <div>108</div>
      </li>
      <li>
        <p>W domu „gagarina” </p> <div>109</div>
      </li>
      <li>
        <p>Żegnaj Gobi </p> <div>112</div>
      </li>
      <li>
        <p>Step by step </p> <div>116</div>
      </li>
      <li>
        <p>Po tamtej stronie </p> <div>124</div>
      </li>
      <li>
        <p>Pod gołym niebem </p> <div>130</div>
      </li>
      <li>
        <p>Ulaanbaatar bez zdjęcia </p> <div>137</div>
      </li>
      <li>
        <p>Mongolska Syberia </p> <div>139</div>
      </li>
      <li>
        <p>Pan samego siebie</p>
        <div>145</div>
      </li>
      <li>
        <p>Mijesce, które mi się śni </p>
        <div>153</div>
      </li>
    </ul>

    {/* THIRD COLUMN */}
    <ul className="third-child">
      <li>
        <p>Dobrzy ludzie</p> <div>154</div>
      </li>
      <li>
        <p>Iryndala </p> <div>158</div>
      </li>
      <li>
        <p>Darkhan niebezpieczny nocą</p> <div>163</div>
      </li>
      <li>
        <p>Ajmak Selenge </p> <div>165</div>
      </li>
      <li>
        <p>Sharyn Gol</p> <div>166</div>
      </li>
      <li>
        <p>Step moim prawdziwym domem</p> <div>172</div>
      </li>
      <li>
        <p>Powtórka z rozrywki </p> <div>182</div>
      </li>
      <li>
        <p>Góry</p> <div>183</div>
      </li>
      <li>
        <p>To już? </p> <div>186</div>
      </li>
      <li>
        <p>Tak, już </p> <div>193</div>
      </li>
      <li>
        <p>Kuriozum </p> <div>194</div>
      </li>
      <li>
        <p>O jeden krok za daleko</p> <div>197</div>
      </li>
      <li>
        <p>Me jo </p> <div>199</div>
      </li>
    </ul>
  </StyledChaptersList>
);
export default ChaptersList;
