import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import styled from 'styled-components';
import HeaderContent from '../molecules/HeaderContent';
import SectionSeparator from '../atoms/SectionSeparator';

const StyledHeader = styled.header`
  width: 100vw;
  height: 100vh;
  max-width: 100%;

  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(178, 143, 106, 1) 0%,
    rgba(29, 49, 82, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(178, 143, 106, 1) 0%,
    rgba(29, 49, 82, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(178, 143, 106, 1) 0%,
    rgba(29, 49, 82, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#1d3152",GradientType=1);
`;

const StyledBgImage = styled(BgImage)`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: relative;

  transition: opacity 1s ease-in-out;
`;

const StyledSectionSeparator = styled(SectionSeparator)`
  min-height: 50px;
  @media ${({ theme }) => theme.media.mobile} {
    min-height: 80px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    min-height: 100px;
  }
`;

const query = graphql`
  query {
    backgroundImage1: file(
      relativePath: { eq: "naglowek-szum-stepu-i-pustyni-ksiazka-slajd-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
    }
    backgroundImage2: file(
      relativePath: { eq: "naglowek-szum-stepu-i-pustyni-ksiazka-slajd-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
    }
    backgroundImage3: file(
      relativePath: { eq: "naglowek-szum-stepu-i-pustyni-ksiazka-slajd-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
    }
  }
`;

const Header = () => {
  const {
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
  } = useStaticQuery(query);

  const IMAGES = [backgroundImage1, backgroundImage2, backgroundImage3];
  let index = 0;

  const [currentBgImage, setCurrentBgImage] = useState(backgroundImage1);

  useEffect(() => {
    setInterval(() => {
      index += 1;
      index %= IMAGES.length;
      setCurrentBgImage(IMAGES[index]);
    }, 10000);
  }, []);

  return (
    <StyledHeader id="Header">
      <StyledBgImage critical image={getImage(currentBgImage)}>
        <HeaderContent />
        <StyledSectionSeparator />
      </StyledBgImage>
    </StyledHeader>
  );
};

export default Header;
