import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import propTypes from 'prop-types';

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute !important;
  width: 100%;
  bottom: -1px;
`;

const SectionSeparator = ({ className }) => {
  const { data } = useStaticQuery(graphql`
    {
      data: file(relativePath: { eq: "naglowek-separator.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            tracedSVGOptions: {
              color: "#cca57e"
              background: "rgba(0, 0, 0, 0)"
            }
            quality: 90
          )
        }
      }
    }
  `);

  return (
    <StyledGatsbyImage
      className={className}
      alt="logo-szum-stepu-i-pustyni"
      image={getImage(data.childImageSharp)}
    />
  );
};

SectionSeparator.propTypes = {
  className: propTypes.string,
};

SectionSeparator.defaultProps = {
  className: '',
};

export default SectionSeparator;
