import React from 'react';
import styled from 'styled-components';

const ChaptersHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const StyledChaptersHeader = styled.h2`
  margin-bottom: 15px;
  text-align: center;
  position: relative;
  font-size: 32px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 400%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &::before {
    left: 105%;
  }
  &::after {
    right: 105%;
  }
`;

const ChaptersHeader = () => (
  <ChaptersHeaderWrapper>
    <StyledChaptersHeader>Spis Treści</StyledChaptersHeader>
  </ChaptersHeaderWrapper>
);

export default ChaptersHeader;
