import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';
import Button from '../atoms/Button';
import Title from '../atoms/Title';

const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 15%;
`;

const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    width: 170px;
    padding: 15px 20px;
    font-size: 11px;
    margin: 0px 5px 10px;
  }

  @media ${({ theme: { media } }) => media.mobile} {
    margin-top: 20px;
    button {
      width: 190px;
      font-size: 13px;
    }
  }
  @media ${({ theme: { media } }) => media.tablet} {
    margin-top: 0px;
    flex-direction: row;
    button {
      padding: 15px 35px;
      width: 210px;
    }
  }
`;

const HeaderContent = () => (
  <StyledHeaderContent >
    <Title />
    <StyledButtons>
      <Button text="Zamów online" onClick={() => scrollTo('#Zamowienie')} />
      <Button
        text="Dowiedz się więcej"
        light
        onClick={() => scrollTo('#About')}
      />
    </StyledButtons>
  </StyledHeaderContent>
);

export default HeaderContent;
