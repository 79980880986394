import React from 'react';
import styled from 'styled-components';
import BookWithQuote from '../molecules/BookWithQuote';
import Form from '../molecules/Form';
import { BiCheckbox } from 'react-icons/all';

const StyledOrder = styled.section`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
`;

const OrderWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media ${({ theme: { media } }) => media.laptop} {
    grid-column-gap: 30px;
  }
`;

const Order = () => (
  <StyledOrder>
    <OrderWrapper>
      <BookWithQuote />
      <Form />

    </OrderWrapper>
  </StyledOrder>
);

export default Order;
